<template>
 <div>
    <v-row no-gutters class="mt-2">
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
        <template v-else>
          <v-toolbar dense  dark color="primary">
            
                   <span class="_headtext-md"><v-icon left>mdi-account-multiple-outline</v-icon> MEMBERS</span>
                   <v-spacer />
                   <!-- <v-icon color="success" @click="state='new',form=true">mdi-plus-circle</v-icon> -->
                </v-toolbar>
          <!-- <v-form  
              
                ref="form"
                v-model="valid"
                lazy-validation
                 autocomplete="off"
                style="width: 100%; margin: auto;" -->
              
            <v-card tile class="pa-1">
               <!-- <v-text-field 
                    v-model.trim="search"
                    label="Search Account"
                    dense
                    hide-details=""
                    outlined
                />
                -->

                    <v-list
      subheader
      two-line
    >
    
      <v-list-item
        v-for="item in players"
        :key="item.id"
      >
        <v-list-item-content>
          <v-list-item-title>
           
            {{item.fullname.toUpperCase()}}
          </v-list-item-title>

          <v-list-item-subtitle>Acct. No.:  {{item.id}}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon color="info" small right @click="state='update', account= item, form=true">mdi-account-cog-outline</v-icon>
            <v-icon color="success" small right @click="account= item, transform=true">mdi-format-list-text</v-icon>
          </v-list-item-icon>
      </v-list-item>

    </v-list>
            </v-card>
          <!-- </v-form> -->
        </template>
        </v-col>
    </v-row>
 <va-account-form :show="form" :state="state" :data="account" @DialogEvent="fEvent"/>
 <reports-account-transaction :show="transform" :data="account" @DialogEvent="tEvent"/>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        state: "new",
        form: false,
        transform: false,
      to_menu: false,
      loading: false,
      valid: false,
      account: {},
      accountfound: false,
        search:'',
        players:[],
        amount:null,
        from_date:'',
        account_no:null,
        headers: [
               { text: 'Name', value:"fullname"},
                  { text: 'action', value:"fullname"},
        ],
        ticket_code:"",
         nameRules: [
        v => !!v || 'Field is required'
      ],
    }),
    beforeDestroy(){
      console.log("beforeDestroy")
      //this.setToolbar(true)
    },
    created() {
      this.PING()
        this.setDrawer(false)
      // this.setLoggedIn(true)
       this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token
        this.getPlayers()
       // this.setToolbar(false)
        
    },
     mounted(){
       console.log("mounted")
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
        this.setOverlayLoading(false)
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      }
    },
    methods: {
      ...mapMutations(['setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      tEvent(){
        this.transform = false
      },
      fEvent(){
          this.form = false
            this.getPlayers()
      },
       validate () {
           
      if (this.$refs.form.validate()) {
         this.addLoad()
       }
      },
      getPlayers(){
          console.log({id: this.$IsNum(this.user.id), role: this.user.role})
         this.$http.post("account/get_players",{id: this.$IsNum(this.user.id), role: this.user.role}).then(response => {
            response.data.list != null?this.players=response.data.list:this.players = []
            console.log(response.data.list)
          }).catch(e => {
            console.log(e.data)
          })
      },
       userDetails(){
          this.account = {}
          this.accountfound = false
          this.$http.post("user/details",{id: this.$IsNum(this.account_no)}).then(response => {
              if(response.data.status) {
                 this.account = response.data.account
                  this.accountfound = true
              } else {
                this.VA_ALERT("error", response.data.message)
              }
          }).catch(e => {
            console.log(e.data)
          })
    },
      addLoad(){
        var param = {}
        param.type = "Transfer Load"
        param.description = "Transfer Load"
        param.amount = this.$IsNum(this.amount.trim())
        param.account = this.$IsNum(this.account_no.trim())
        this.$http.post("wallet/load",param).then(response => {
              if(response.data.status) {
                  this.VA_ALERT('success', response.data.message)
                  this.amount = 0
                  this.account_no = 0
                  this.accountfound = false
                  this.$refs.form.reset()
              } else {
                  this.VA_ALERT('error', response.data.message)
              }
          }).catch(e => {
            console.log(e.data)
          })
      }
    },
  }
</script>